<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline points="9 30 3 30 3 15 9 15" fill="none" stroke-miterlimit="10" stroke-linecap="butt" /> <path d="M9,15,13,1h1a3,3,0,0,1,3,3V14h9a3,3,0,0,1,3,3v1l-1.774,9.547A3,3,0,0,1,24.28,30H9Z" fill="none" stroke-miterlimit="10" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>