<template>
	<div class="block-inner">
		<header v-html="question"></header>
		<div class="options">
			<label class="option-true">
				<input type="radio" :name="block.id" :checked="hasAnswerOption(trueOption.id)" @input="sendAnswerOptions(trueOption, optionGroup)" />
				<IconThumbUp :width="32" :height="32" />
				<p>{{ trueOption.label }}</p>
			</label>
			<label class="option-false">
				<input type="radio" :name="block.id" :checked="hasAnswerOption(falseOption.id)" @input="sendAnswerOptions(falseOption, optionGroup)" />
				<IconThumbDown :width="32" :height="32" />
				<p>{{ falseOption.label }}</p>
			</label>
		</div>
	</div>
</template>

<script>
	import IconThumbUp from '../icons/IconThumbUp';
	import IconThumbDown from '../icons/IconThumbDown';
	import answerMixin from '../../mixins/answerMixin';
	import {jsonToHtml} from '../../inc/text';

	export default {
		components: {
			IconThumbUp,
			IconThumbDown
		},
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			trueOption() {
				return this.options[0];
			},
			falseOption() {
				return this.options[1];
			},
			optionGroup() {
				const keys = Object.keys(this.block.options);

				return keys.pop();
			},
			options() {
				if(this.optionGroup && this.optionGroup.length) {
					return this.block.options[this.optionGroup];
				}

				return [];
			},
			question() {
				return jsonToHtml(this.block.content);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-group {
		flex-flow: row wrap;
	}

	.input-group-row {
		margin-top: 10px;
		margin-right: $default_padding;
	}

	.block-inner {
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		header {
			flex: 1;
			margin-bottom: 0;
			font-weight: inherit !important;
		}

		.options {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-evenly;
		}

	}

	@include breakpoint('small') {
		.block-inner {
			display: block;

			header {
				margin-bottom: $default_padding;
			}
		}
	}

	.option-true,
	.option-false {
		position: relative;
		padding: 0 $default_padding * 2;
		cursor: pointer;

		p {
			font-size: 0.8em;
			text-transform: uppercase;
			text-align: center;
			margin-top: 5px;
			margin-bottom: 0;
		}

		input[type='radio'] {
			position: absolute;
			visibility: hidden;
		}
	}

	.option-true::v-deep {
		polyline,
		path {
			fill: rgba($color: $color__green, $alpha: 0);
			transition: fill 0.2s ease;
		}

		&:hover {
			polyline,
			path {
				fill: rgba($color: $color__green, $alpha: 0.4);
			}
		}

		input:checked {
			& ~ p {
				font-weight: bold;
			}

			& + svg {
				polyline,
				path {
					fill: rgba($color: $color__green, $alpha: 0.8);
				}
			}
		}
	}

	.option-false::v-deep {
		polyline,
		path {
			fill: rgba($color: $color__red, $alpha: 0);
			transition: fill 0.2s ease;
		}

		&:hover {
			polyline,
			path {
				fill: rgba($color: $color__red, $alpha: 0.4);
			}
		}

		input:checked + svg {
			& ~ p {
				font-weight: bold;
			}

			polyline,
			path {
				fill: rgba($color: $color__red, $alpha: 0.8);
			}
		}
	}

</style>